// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

.panda-image {
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 0.25rem;
}

.tooltip.show {
  opacity: 1;
}
